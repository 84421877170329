import React, {useEffect, useState} from 'react';
import {useAppContext} from 'contexts';
import {JobColumns} from 'shared';
import {jobButtonsHandler, JobStatus} from 'hooks';
import {JobTableRender} from 'components';
import {formatPrice, jobId} from 'shared/helpers';
import {isDeviceSmall, useCustomMediaQuery} from 'shared/helpers/DeviceSize';
import {merge} from 'lodash';
import {DateTime, DateType, Format} from '@cabinetsbycomputer/datetime';

type JobTableProps = {
    title: string;
    jobData: object;
    className: string;
    columns: object;
    link: string;
    mobileRender: boolean;
    tableOrder: number;
    tablesWithValue: number;
    fromApi: boolean;
    api: string;
};

const JobTable = ({
    title,
    jobData,
    className,
    columns,
    link,
    mobileRender,
    tableOrder,
    tablesWithValue,
    fromApi,
    api,
}: JobTableProps) => {
    const isSearch = className == 'search-jobs' ? true : false;
    const isSmallDevice = isDeviceSmall();
    const fullWidthTableThreshold = useCustomMediaQuery({maxWidth: 992});
    const {userProfile} = useAppContext();
    const [limit, setLimit] = useState(isSmallDevice ? 10 : 15);
    const [order, setOrder] = useState({
        fields: [9],
        order: 'desc',
    });

    useEffect(() => {
        setLimit(isSmallDevice ? 10 : 15);
    }, [isSmallDevice]);

    /**
     * Get Job Available Buttons
     */
    const {buttons, dialog} = jobButtonsHandler(isSearch);

    /**
     * Build Header Columns of Table
     */
    const buildTableHeaders = (columns: Object): Object => {
        let fieldHeader = [];

        columns.forEach((column) => {
            if (
                JobColumns[column].hasOwnProperty('displayProp') &&
                userProfile[JobColumns[column].displayProp] == 0
            ) {
                return;
            }

            fieldHeader.push({
                fieldName: column,
                title: JobColumns[column].title,
                format: (value, row) =>
                    columnFormatter(JobColumns[column].type, value, row),
                alignLeft: JobColumns[column].alignLeft,
            });
        });

        return fieldHeader;
    };

    /**
     * Returns Formatted Value
     * of Column selected
     */
    const columnFormatter = (
        columnType: string,
        value: string | number | boolean,
        row: object
    ): string | number | boolean => {
        switch (columnType) {
            case 'id':
                return jobId(value);
            case 'status':
                const jobStatus = userProfile?.jobStatuses?.find(
                    (item) => item.status === value
                );
                if (!!jobStatus && jobStatus?.custom_label) {
                    // status with custom label, others will get from default mapping
                    return jobStatus.custom_label;
                }
                if (typeof JobStatus[value as number] !== 'undefined') {
                    return JobStatus[value as number];
                }
                return '-';
            case 'price':
                return formatPrice(row?.productCount > 0 ? value : 0, row);
            case 'date':
                try {
                    let date: DateTime;

                    if (
                        value !== null &&
                        typeof value === 'object' &&
                        'date' in value
                    ) {
                        date = DateTime.parse(value.date as string);
                    } else {
                        date = DateTime.parse(value as string, DateType.ISO);
                    }

                    return date
                        .format(Format.DayMonthAtTimeFormat)
                        .addOrdinalSuffix()
                        .toString();
                } catch (e) {
                    return '-';
                }
            default:
                return value;
        }
    };

    /**
     * Determines width of Table for UI
     */
    const tableWidthSetting = (
        tablesWithValue: number,
        tableOrder: number
    ): number => {
        let tableWidth = 12;

        if (fullWidthTableThreshold) return tableWidth;

        if (tablesWithValue % 2 == 1 && tableOrder > 0) {
            tableWidth = tableOrder % 2 == 0 ? 5 : 7;
        } else if (tablesWithValue % 2 == 0) {
            tableWidth = tableOrder % 2 == 0 ? 7 : 5;
        }

        return tableWidth;
    };

    /**
     * Build Table Options
     */
    let tableOption = {
        fields: buildTableHeaders(columns),
        data: jobData,
        hasCursor: true,
        options: buttons,
    };

    if (fromApi) {
        const apiData = {
            fromApi: fromApi,
            api: api,
            pagination: true,
            limit: limit,
            orderBy: order,
        };

        tableOption = merge(tableOption, apiData);
    }

    /**
     * Job Table Settings
     */
    const jobTableData = [
        {
            title: title,
            length: jobData ? jobData.length : 0,
            tableOption: tableOption,
            link: link,
            mobileRender: mobileRender,
            className: className,
        },
    ];

    /**
     * Call Render Component
     */
    return (
        <>
            <JobTableRender
                jobTableData={jobTableData}
                isSearch={isSearch}
                mdValue={tableWidthSetting(tablesWithValue, tableOrder)}
            />
            {dialog}
        </>
    );
};

export {JobTable};
